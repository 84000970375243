// DISCIPLINARY SEARCH

jQuery(document).ready(function ($) {

  $('.c-disciplinary-search__btn').click(function () {
    $(this).toggleClass('active');
    $('.c-disciplinary-search__box').toggleClass('active');
  });
  
});
