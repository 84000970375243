/* Sidebar Nav Accordion */


jQuery(document).ready(function ($) {

  $('.c-sidebar-nav > ul li span.moon-arrow-down2').attr('tabindex', '0');

  $('.c-sidebar-nav > ul > li span.moon-arrow-down2').click(function () {
    $(this).parent('li').toggleClass('active');
  });

  $('.c-sidebar-nav > ul > li span.moon-arrow-down2').on('keypress', function (e) {
    if (e.which == 13) {
      $(this).trigger('click');
    }
  });
  
});
