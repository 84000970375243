// ISOTOPE

jQuery(document).ready(function($){

  var $grid = $('.grid').isotope({
    // options
    itemSelector: '.grid-item',
    layoutMode: 'fitRows'
  });

  // get Isotope instance 
  var iso = $('.grid').data('isotope')
  if ( iso ) {
    $('.grid').addClass('iso-active');
  }

 /*
  // layout Isotope after each image loads
  $grid.imagesLoaded().progress( function() {
    $grid.isotope('layout');
  });

  */

  // filter items on button click
  $('.c-isotope-filter').on( 'click', 'li', function() {
    var filterValue = $(this).attr('data-filter');
    $grid.isotope({ filter: filterValue });
    $('.c-isotope-filter li').removeClass('active');
    $(this).addClass('active');
  });


  $('.c-isotope-filter li').click(function() {

    setTimeout( function() {
      $('img.lazy').lazy({
          bind: "event",
          // called after an element was successfully handled
            afterLoad: function(element) {
              element.addClass('loaded');
          }
        });
      }, 300);

    });

});
