// LANGUAGE SELECT

jQuery(document).ready(function($){

  $('.c-country-selector > a').click(function(event) {
    event.stopPropagation();
    $(this).next('ul').toggleClass('active');
  });

  $(document).click(function(event) { 
    if(!$(event.target).closest('.c-country-selector ul').length) {
      if($('.c-country-selector ul').is(":visible")) {
        $('.c-country-selector ul').removeClass('active');
      }
    }
  });

});
