// MMENU

jQuery(document).ready(function() {
	jQuery("#menu").mmenu({
		counters: false,
	   "extensions": [
		  "pagedim-black",
		  "pageshadow",
		  "theme-light"
	   ],
	   "offCanvas": {
		  "position": "right",
		  "zposition" : "front"
	   },
     navbar: {
        title: ""
      },
	   "navbars": [
		  {
			 "position": "top",
			 "content": [
			 	"<a class='moon-house' href='#'></a>",
				"<a class='moon-x' href='#'></a>"
			 ]
		  }
	   ]
	});
			var API = jQuery("#menu").data( "mmenu" );
	
			  jQuery(".moon-x").click(function() {
				 API.close();
        });
        
        // FOUC - Show main and footer elements as soon as presentable.

        $('body').addClass('loaded');
 });
