/* Sidebar Nav Mobile */


jQuery(document).ready(function ($) {

  $('.c-sidebar-nav--btn').click(function () {
    $(this).toggleClass('active');
    $(this).next('ul').toggleClass('active');
    $('.c-sidebar-nav_sub').toggleClass('active');
  });

});
