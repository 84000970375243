/* Accordion */

jQuery( function($) {
  $( ".c-accordion" ).accordion({
    heightStyle: "content",
    header: '.c-accordion__block > h3',
    collapsible: true,
    active: false,
    animate: 240,
    create: function(event,ui) {
      $(document).on('click', '.ui-accordion-header', function () {
        $(this).next('div').find('.c-carousel').resize();
      })
    }
  });
});
