// MEGA MENU

jQuery(document).ready(function ($) {

  $('.c-main-nav > ul > li').hover(function () {
    $(this).addClass('active');
  });

  $('.c-main-nav > ul > li').mouseleave(function () {
    $(this).removeClass('active');
  });

  $('.sidenav li').hover(function () {
    $(this).addClass('active');
    $(this).find('.c-mega-menu--main').addClass('active');
  });

  $('.sidenav li').mouseleave(function () {
    $(this).removeClass('active');
    $(this).find('.c-mega-menu--main').removeClass('active');
  });
  
});
