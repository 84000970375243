// Lazy Load

$(function() {
    $('.lazy').lazy({
        
        // called after an element was successfully handled
        afterLoad: function(element) {
            element.addClass('loaded');
        }
    
    });
});
