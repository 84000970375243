// Quick Nav

jQuery(document).ready(function($) {

    if ($(window).width() > 1023) {

        /*
        $("nav.quick-nav ul li a").on("click", function() {

            $("body, html").animate({ 
                scrollTop: $( $(this).attr('href') ).offset().top - 124
            }, 600);

        });
        */

        $('nav.quick-nav').stickit({top: 0});
    }
});

jQuery(document).ready(function($) {

    $(".scroll-nav").scrollspy({ offset: - 124 });

});
