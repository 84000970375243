/* Scroll Top */

jQuery(document).ready(function($){

// When the user scrolls the page, execute myFunction
window.onscroll = function() {scrollHeader()};

// Get the header
var header = document.getElementById("header");
var body = document.getElementsByTagName("BODY")[0];

// Get the offset position of the navbar
var sticky = 121;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function scrollHeader() {
  if (window.pageYOffset > sticky) {
		header.classList.add("sticky");
		body.classList.add("stickybody");
  } else {
		header.classList.remove("sticky");
		body.classList.remove("stickybody");
  }
}

scrollHeader();

});
