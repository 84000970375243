/* Carousel Slider 3 Col */

jQuery(document).ready(function($){
  $('.c-carousel-3').slick({
	  autoplay: false,
	  autoplaySpeed: 4000,
	  speed: 800,
	  pauseOnHover: true,
	  arrows: true,
	  dots: true,
	  infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
	  responsive: [
	  	{
		breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				fade: false,
				arrows: false,
				dots: true,
				speed: 800
			}
		},
		{
		breakpoint: 420,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: false,
				arrows: false,
				dots: true,
				speed: 800
			}
		}
		// You can unslick at a given breakpoint now by adding:
		// settings: "unslick"
		// instead of a settings object
	  ]
  });
});

/* Carousel Slider 4 Col */

jQuery(document).ready(function($){
  $('.c-carousel-4').slick({
	  autoplay: false,
	  autoplaySpeed: 4000,
	  speed: 800,
	  pauseOnHover: true,
	  arrows: true,
	  dots: true,
	  infinite: false,
		slidesToShow: 4,
		slidesToScroll: 1,
	  responsive: [
			{
		breakpoint: 1023,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				fade: false,
				arrows: true,
				dots: true,
				speed: 800
			}
		},
	  	{
		breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				fade: false,
				arrows: false,
				dots: true,
				speed: 800
			}
		},
		{
		breakpoint: 420,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: false,
				arrows: false,
				dots: true,
				speed: 800
			}
		}
		// You can unslick at a given breakpoint now by adding:
		// settings: "unslick"
		// instead of a settings object
	  ]
  });
});

// Contact Slider

jQuery(document).ready(function($){

  $('.c-contact-slider').slick({
	  autoplay: false,
	  speed: 500,
	  arrows: false,
		dots: false,
		fade: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		draggable: false,
		swipe: false,
		asNavFor: '.c-contact-slider--nav'
	});

	$('.c-contact-slider--nav').slick({
	  autoplay: false,
	  speed: 500,
	  arrows: true,
	  dots: false,
	  infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		asNavFor: '.c-contact-slider',
		centerMode: true,
		focusOnSelect: true,
		swipe: false,
		responsive: [
	  	{
			breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					fade: false
				}
			},
			{
			breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					fade: false
				}
			}
	  ]
	});

});
