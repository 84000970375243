// FOOTER MENU

jQuery(document).ready(function ($) {

  $('.footer-menu .col h3 span').click(function() {
    $(this).parent('h3').toggleClass('active');
    $(this).parent('h3').next('ul').toggleClass('active');
  }); 

});
